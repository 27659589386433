import React from "react";

import { Link } from "gatsby";

import { useSiteMetadata } from "@/hooks";

import * as styles from "./Author.module.scss";

const Author = () => {
  const { author, copyright, subtitle, title } = useSiteMetadata();

  return (
    <div className={styles.author}>
      <h2 className={styles.title}>
        <Link className={styles.link} to="/">
          {title}
        </Link>
      </h2>
      <p className={styles.subtitle}>{subtitle}</p>
      <h3 className={styles.title}>
        <Link className={styles.link} to="/">
          {author.name}
        </Link>
      </h3>
      <p className={styles.subtitle}>{author.bio}</p>
      <div className={styles.copyright}>{copyright}</div>
    </div>
  );
};

export default Author;
